*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #E9EDF5;
  height: 100vh;
  box-sizing: border-box;
}

body {
  height: 100vh;
  box-sizing: border-box;
}

#root {
  height: 100vh;
  box-sizing: border-box;
}

p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.card-frame,
.card-number-frame,
.expiry-date-frame,
.cvv-frame {
  height: 52px;
}

#schemeChoice {
  height: 200px !important;
}

#payment-form {
  width: 280px;
  margin: 0 auto;
}

label {
  display: block;
  height: 10px;
  /* color: #13395e; */
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}

.date-and-code {
  display: flex;
  margin-bottom: 8px;
}

.date-and-code>div:nth-child(1) {
  width: 55.715%;
}

.date-and-code>div:nth-child(2) {
  width: 45.719%;
}

.input-container {
  position: relative;
  display: flex;
  height: 40px;
}

.icon-container:last-child {
  right: 0;
}

.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}

.input-container.expiry-date {
  margin-right: 4px;
}

.input-container.cvv {
  margin-left: 4px;
}

input#checkout-frames-card-number {
  font-size: 444px;
  background-color: black;
}

.frame--activated>#checkout-scheme-choice-frame-wrapper>iframe#cardNumber>#document>html>body>#frames-element-card-number>div>input#checkout-frames-card-number.card-number--focus {
  padding-top: 20px !important;
}

iframe#cardNumber>#document>html>body>#frames-element-card-number>div>input#checkout-frames-card-number.card-number--valid {
  padding-top: 20px !important;
}

iframe#cardNumber>#document>html>body>#frames-element-card-number>div>input#checkout-frames-card-number.card-number--invalid {
  padding-top: 20px !important;
}

input#checkout-frames-card-number::placeholder {
  color: transparent !important
}

.card-number--focus {
  padding-top: 20px !important;
}

.card-number--valid {
  padding-top: 20px !important;
}

.card-number--invalid {
  padding-top: 20px !important;
}

.cardnumber-iframe {
  font-size: 12px;
  border-radius: 12px;
  background-color: white;
}

.expiry-date-iframe {
  font-size: 12px;
  border-radius: 12px;
  background-color: white;
}

/* .expiry-date-frame {
  padding-left: 40px;
} */

/* .cvv-frame {
  padding-left: 40px;
} */

div+button {
  margin-top: 8px;
}

.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}

.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$='-error'] {
  display: none;
}

.frame {
  opacity: 0;
}

iframe#cardNumber>#document>html {
  color: black;
}

.frame--activated.frame--invalid {
  border: solid 1px red;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
  border-radius: 12px;
  overflow: hidden;
}

/* frame--activated {
  opacity: 1;
  padding: 8px;
  border: solid 1px #13395e;
  
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
} */

input.card-number.field.card-number--hover.card-number--invalid {
  color: red;
}

.frame--activated.frame--valid {
  border: solid 1px #07cf07;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
  border-radius: 12px;
  overflow: hidden;
}

.error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

#pay-button {
  margin-top: 150px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  height: 40px;
  width: 100%;
  background-color: #13395e;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:active {
  background-color: #0b2a49;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:hover {
  background-color: #15406b;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:disabled {
  background-color: #697887;
  box-shadow: none;
}

#pay-button:not(:disabled) {
  cursor: pointer;
}

.success-payment-message {
  color: #13395e;
  line-height: 1.4;
}

.token {
  color: #b35e14;
  font-size: 0.9rem;
  font-family: monospace;
}

#commnet_inputfield {
  padding: 12px 8px;
  border: none;
  border-radius: 12px;
  width: 100%;
}

#commnet_inputfield::placeholder {
  color: #434854;
  font-weight: 400;
  font-size: 14px;
}

#commnet_inputfield:focus-visible {
  border-color: transparent;
  outline: none;
}

#amount_inputfield {
  padding: 0px 8px;
  border: none;
  color: #09181A;
  background-color: transparent;
  font-weight: 890;
  font-size: 38px;
  width: 100%;
}

#amount_inputfield:focus-visible {
  border-color: transparent;
  outline: none;
}

.gpay-button {
  width: 100% !important;
  border-radius: 12px !important
}

.horizontal-line {
  position: relative;
}

.horizontal-line::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 14px;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}

.loader {
  animation: spinner 1s linear infinite;

}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}